var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artists"},[_c('div',{staticClass:"artists-item"},[_c('a',{staticClass:"artists-item__href",attrs:{"href":"../mike-parsons"}},[_c('div',{staticClass:"artists-item__img",style:({backgroundImage: `url(${require('@/assets/images/artist-mikeparsons.jpg')})`})}),_vm._m(0),_vm._m(1)])]),_c('div',{staticClass:"artists-item"},[_c('a',{staticClass:"artists-item__href",attrs:{"href":"../derek-raulerson"}},[_c('div',{staticClass:"artists-item__img",style:({backgroundImage: `url(${require('@/assets/images/artist-derek.jpg')})`})}),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"artists-item"},[_c('a',{staticClass:"artists-item__href",attrs:{"href":"../kurtis-todd"}},[_c('div',{staticClass:"artists-item__img",style:({backgroundImage: `url(${require('@/assets/images/artist-kurtistodd.jpg')})`})}),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"artists-item"},[_c('a',{staticClass:"artists-item__href",attrs:{"href":"../ryan-coniglario"}},[_c('div',{staticClass:"artists-item__img",style:({backgroundImage: `url(${require('@/assets/images/artist-ryan.jpg')})`})}),_vm._m(6),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"artists-item__title"},[_vm._v("Mike "),_c('span',[_vm._v("Parsons")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artists-item__overlay"},[_c('h2',{staticClass:"artists-item__overlay-title"},[_vm._v("Mike Parsons")]),_c('span',[_vm._v("View Portfolio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"artists-item__title"},[_vm._v("Derek "),_c('span',[_vm._v("Raulerson")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artists-item__overlay"},[_c('h2',{staticClass:"artists-item__overlay-title"},[_vm._v("Derek Raulerson")]),_c('span',[_vm._v("View Portfolio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"artists-item__title"},[_vm._v("Kurtis "),_c('span',[_vm._v("Todd")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artists-item__overlay"},[_c('h2',{staticClass:"artists-item__overlay-title"},[_vm._v("Kurtis Todd")]),_c('span',[_vm._v("View Portfolio")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"artists-item__title"},[_vm._v("Ryan "),_c('span',[_vm._v("Coniglario")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"artists-item__overlay"},[_c('h2',{staticClass:"artists-item__overlay-title"},[_vm._v("Ryan Coniglario")]),_c('span',[_vm._v("View Portfolio")])])
}]

export { render, staticRenderFns }