<template>
    <div class="artists">
        <div class="artists-item">
            <a href="../mike-parsons" class="artists-item__href">
                 <div class="artists-item__img" :style="{backgroundImage: `url(${require('@/assets/images/artist-mikeparsons.jpg')})`}"></div>
                <h2 class="artists-item__title">Mike <span>Parsons</span></h2>
                <div class="artists-item__overlay">
                    <h2 class="artists-item__overlay-title">Mike Parsons</h2>
                    <span>View Portfolio</span>
                </div>
            </a>
        </div>
        <div class="artists-item">
            <a href="../derek-raulerson" class="artists-item__href">
                <div class="artists-item__img" :style="{backgroundImage: `url(${require('@/assets/images/artist-derek.jpg')})`}"></div>
                <h2 class="artists-item__title">Derek <span>Raulerson</span></h2>
                <div class="artists-item__overlay">
                    <h2 class="artists-item__overlay-title">Derek Raulerson</h2>
                    <span>View Portfolio</span>
                </div>
            </a>
        </div>
        <div class="artists-item">
            <a href="../kurtis-todd" class="artists-item__href">
                <div class="artists-item__img" :style="{backgroundImage: `url(${require('@/assets/images/artist-kurtistodd.jpg')})`}"></div>
                <h2 class="artists-item__title">Kurtis <span>Todd</span></h2>
                <div class="artists-item__overlay">
                    <h2 class="artists-item__overlay-title">Kurtis Todd</h2>
                    <span>View Portfolio</span>
                </div>
            </a>
        </div>
         <div class="artists-item">
            <a href="../ryan-coniglario" class="artists-item__href">
                <div class="artists-item__img" :style="{backgroundImage: `url(${require('@/assets/images/artist-ryan.jpg')})`}"></div>
                <h2 class="artists-item__title">Ryan <span>Coniglario</span></h2>
                <div class="artists-item__overlay">
                    <h2 class="artists-item__overlay-title">Ryan Coniglario</h2>
                    <span>View Portfolio</span>
                </div>
            </a>
        </div>
        <!-- <div class="artists-item">
            <a href="../elijah-ramos" class="artists-item__href">
                <div class="artists-item__img" :style="{backgroundImage: `url(${require('@/assets/images/artist-elijah.jpg')})`}"></div>
                <h2 class="artists-item__title">Elijah <span>Ramos</span></h2>
                <div class="artists-item__overlay">
                    <h2 class="artists-item__overlay-title">Elijah Ramos</h2>
                    <span>View Portfolio</span>
                </div>
            </a>
        </div> -->
    </div>
</template>

<script>
// Components

// Export
export default {
	name: 'artists',
}
</script>

<style lang="scss" scoped>

.artists {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #191919;

    @media screen and (max-width: map-get($breakpoints, 'md')) {
        background: color_('grey','xdark');
        flex-direction: column;
        flex-wrap: nowrap;
    }

    &-item {
        flex: 100% 1 0;
        overflow: hidden;
        position: relative;
        transition: all 350ms $easing-cubic;
        max-height: 90vh;
        // min-height: 700px;

        @media screen and (max-width: map-get($breakpoints, 'md')) {
            flex: 1;
        }

        &:hover {
            cursor: pointer;

            .artists-item__overlay {
                opacity: 1;

                &-title {
                    opacity: 1;
                    transform: translateY(0);
                }

                span {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

           .artists-item__title {
                opacity: 0;
            }

           .artists-item__img {
                transform: scale(1.15);
            }
        }

        .home-artists-wrapper & {
            flex: 50% 0 0;
            min-height: 600px;

            @media screen and (max-width: map-get($breakpoints, 'xl')) {
                min-height: 0px;
            }
        }

        &__overlay {
            background: rgba(33, 33, 33,.8);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            transition: all 400ms ease-out 0ms;
            opacity: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media screen and (max-width: map-get($breakpoints, 'md')) {
                display: none;
            }

            &-title {
                transition: all 200ms ease-in 200ms;
                transform: translateY(10px);
                opacity: 0;
                font-family: $font-primary;
                color: color_('white');
                text-align: center;
                font-size: 60px;
                font-weight: 700;
            }

            span {
                transform: translateY(10px);
                transition: all 200ms ease-out 300ms;
                opacity: 0;
                display: block;
                text-transform: uppercase;
                letter-spacing: 4px;
                font-size: 18px;
                transform: translateY(20px);
                color: color_('grey');
            }
        }

        &__title {
            position: absolute;
            bottom: 10px;
            padding: 40px;
            color: color_('white');
            font-family: $font-secondary;
            font-weight: 700;
            font-size: 25px;
            letter-spacing: 6px;
            font-weight: 700;
            line-height: 1.1;
            opacity: 1;
            transition: all 400ms ease-in 0ms;

            span {
                font-weight: 300;
            }

            @media screen and (max-width: map-get($breakpoints, 'md')) {
                padding: 0 40px;
            }
        }

        &__img {
            @include ratio('standard');
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            max-width: 100%;
            height: auto;
            transform: scale(1);
            transition: opacity 350ms  $easing-cubic 0ms, transform 10s;

            @media screen and (max-width: map-get($breakpoints, 'md')) {
                width: 100%;
                float:none;
            }

            .artists-wrapper & {
                @include ratio('film');
            }

            .home-artists-wrapper & {
                @include ratio('tall');
            }
        }

        // Enable for odd number of grid elements
        // &:first-child {
        //     flex: 100% 1 0;

        //     & .artists-item__img {
        //         @include ratio('standard');

        //         .home-artists-wrapper & {
        //             @media screen and (max-width: map-get($breakpoints, 'md')) {
        //                 @include ratio('tall');
        //             }
        //         }
        //     }
        // }
    }

}

</style>